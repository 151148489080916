@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-Regular.woff2') format('woff2'),
    url('/fonts/matter/Matter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-RegularItalic.woff2') format('woff2'),
    url('/fonts/matter/Matter-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-Bold.woff2') format('woff2'),
    url('/fonts/matter/Matter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-BoldItalic.woff2') format('woff2'),
    url('/fonts/matter/Matter-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-Light.woff2') format('woff2'),
    url('/fonts/matter/Matter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-LightItalic.woff2') format('woff2'),
    url('/fonts/matter/Matter-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-Medium.woff2') format('woff2'),
    url('/fonts/matter/Matter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-MediumItalic.woff2') format('woff2'),
    url('/fonts/matter/Matter-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-SemiBold.woff2') format('woff2'),
    url('/fonts/matter/Matter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/matter/Matter-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-Heavy.woff2') format('woff2'),
    url('/fonts/matter/Matter-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/matter/Matter-HeavyItalic.woff2') format('woff2'),
    url('/fonts/matter/Matter-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

/* Matter Mono */

@font-face {
  font-family: 'MatterMono';
  src: url('/fonts/matter-mono/MatterMono-Regular.woff2') format('woff2'),
    url('/fonts/matter-mono/MatterMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MatterMono';
  src: url('/fonts/matter-mono/MatterMono-Bold.woff2') format('woff2'),
    url('/fonts/matter-mono/MatterMono-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MatterMono';
  src: url('/fonts/matter-mono/MatterMono-Light.woff2') format('woff2'),
    url('/fonts/matter-mono/MatterMono-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MatterMono';
  src: url('/fonts/matter-mono/MatterMono-Medium.woff2') format('woff2'),
    url('/fonts/matter-mono/MatterMono-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MatterMono';
  src: url('/fonts/matter-mono/MatterMono-SemiBold.woff2') format('woff2'),
    url('/fonts/matter-mono/MatterMono-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MatterMono';
  src: url('/fonts/matter-mono/MatterMono-Heavy.woff2') format('woff2'),
    url('/fonts/matter-mono/MatterMono-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* MatterMono-Bold.woff
MatterMono-Bold.woff2
MatterMono-Heavy.woff
MatterMono-Heavy.woff2
MatterMono-Light.woff
MatterMono-Light.woff2
MatterMono-Medium.woff
MatterMono-Medium.woff2
MatterMono-Regular.woff
MatterMono-Regular.woff2
MatterMono-SemiBold.woff
MatterMono-SemiBold.woff2
MatterSQMono-Bold.woff
MatterSQMono-Bold.woff2
MatterSQMono-Heavy.woff
MatterSQMono-Heavy.woff2
MatterSQMono-Light.woff
MatterSQMono-Light.woff2
MatterSQMono-Medium.woff
MatterSQMono-Medium.woff2
MatterSQMono-Regular.woff
MatterSQMono-Regular.woff2
MatterSQMono-SemiBold.woff
MatterSQMono-SemiBold.woff2 */
